import React, { useEffect, useRef, useState } from "react";
import { instance } from "../../utils/ApiRequest";

function ModalDailogue({ setStlUrl, stlUrl, setIsFileLoaded }) {
  const [files, setFiles] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [isSelectedID, setIsSelectedID] = useState("");
  const [sortBy, setSortBy] = useState("nameAsc");
  const [rename, setRename] = useState("");
  const [tab, setTab] = useState("files");
  const [drag, setDrag] = useState(false);
  const [error, setError] = useState("");
  let dropRef = useRef();
  let dragCounter = 0;

  const closeButtonRef = useRef(null);

  async function GetStlUrl(fileId) {
    sessionStorage.setItem("fileId", fileId);
    await instance
      .get(`/print/user-files/stl/${fileId}`)
      .then((res) => {
        // Trigger click event of .btn-close
        closeButtonRef.current.click();
        setStlUrl(res.data.Data.URL);
        setTimeout(() => {
          setIsFileLoaded(false);
          setIsSelectedID("");
        }, 1000);
      })
      .catch((err) => {
        setError(err.response.data.Message);
      });
  }

  const handleFileChange = async (fileUploaded) => {
    setIsFileLoaded(true);
    if (fileUploaded) {
      const formData = new FormData();
      formData.append("File", fileUploaded);
      await instance
        .post("/print/user-files/upload", formData)
        .then((res) => {
          GetStlUrl(res.data.Data.FileId);
        })
        .catch((err) => {
          setError(err.response.data.Message);
        });
    }
  };

  const HandleSelectFile = (id) => {
    setIsSelectedID(id);
  };

  const handleRunViewer = () => {
    if (isSelectedID) {
      setIsFileLoaded(true);
      GetStlUrl(isSelectedID);
    }
  };

  const RenameFile = (val, index) => {
    setRename(val);
    setEditIndex(index);
  };

  const tabChange = (val) => {
    setTab(val);
    setIsSelectedID("");
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) setDrag(false);
  };

  function validateFile(droppedFile) {
    if (
      droppedFile.name &&
      String(droppedFile.name).endsWith(".stl") &&
      ((droppedFile.type && droppedFile.type.toLowerCase().includes("stl")) ||
        !droppedFile.type)
    ) {
      if (droppedFile.size / 1048576 > 50) {
        setError(
          <div>
            Max file size 50MB is allowed! Need support?{" "}
            <a href={process.env.REACT_APP_SUPPORT_URL}>Contact us</a>
          </div>
        );
      } else {
        handleFileChange(droppedFile);
      }
    } else {
      setError("Please upload .stl files only.");
    }
    setTimeout(() => {
      setError("");
    }, 6000);
  }

  const handleUploadFile = (event) => {
    const droppedFile = event.target.files[0];
    validateFile(droppedFile);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    const droppedFile = e.dataTransfer.files[0];
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      validateFile(droppedFile);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return () => {
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  });

  const RenameChange = (value) => {
    setRename(value);
  };
  const handleClose = () => {
    // Reset edit index
    setEditIndex(-1);
    FetchMyList();
    setIsSelectedID("");
  };
  const handleCancel = () => {
    // Reset edit index
    setEditIndex(-1);
  };
  const handleSearch = (val) => {
    if (val !== "") {
      const searchedFiles =
        files &&
        files.filter((file) => file.PreviewFileName.includes(val.trim()));
      setFiles(searchedFiles);
    } else {
      FetchMyList();
    }
  };

  const updateFileName = async (fileId) => {
    if (rename) {
      const body = { FileId: fileId, NewFileName: rename };
      await instance
        .put("/print/user-files/rename", body)
        .then((res) => {
          FetchMyList();
          setEditIndex(-1);
        })
        .catch((err) => {
        });
    }
    // Reset edit index
  };

  // list of all user files
  async function FetchMyList() {
    await instance
      .get("/print/user-files/list")
      .then((res) => {
        setFiles(res.data.Data);
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    FetchMyList();
  }, [stlUrl]);

  // Function to handle sorting change
  const handleSortChange = (e) => {
    setSortBy(e.target.value);
    FetchMyList();
  };

  // Function to sort files based on sortBy criteria
  const sortFiles = (a, b) => {
    if (sortBy === "nameAsc") {
      return a.PreviewFileName.localeCompare(b.PreviewFileName); // Alphabetical ascending
    } else if (sortBy === "nameDesc") {
      return b.PreviewFileName.localeCompare(a.PreviewFileName); // Alphabetical descending
    } else if (sortBy === "dateAsc") {
      return new Date(a.updatedAt) - new Date(b.updatedAt); // Date ascending
    } else if (sortBy === "dateDesc") {
      return new Date(b.updatedAt) - new Date(a.updatedAt); // Date descending
    }
    return 0; // Default: no sorting
  };

  // Sort files array
  const sortedFiles = [...files].sort(sortFiles);

  return (
    <div
      className="modal user_files fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <ul className="tabs">
              <li
                className={tab === "files" ? "active" : ""}
                onClick={() => tabChange("files")}
              >
                <span>My files</span>
              </li>
              <li
                className={tab === "upload" ? "active" : ""}
                onClick={() => tabChange("upload")}
              >
                <span>Upload</span>
              </li>
            </ul>
            <button
              ref={closeButtonRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className={`modal-body ${tab === "files" ? "active" : ""}`}>
            <div className="search_upload_file">
              <input
                type="text"
                className="form-control"
                maxLength="30"
                name="q"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search Files ..."
              />
              <select
                value={sortBy}
                onChange={handleSortChange}
                className="form-select"
              >
                <option value="nameAsc">Name A-Z</option>
                <option value="nameDesc">Name Z-A</option>
                <option value="dateAsc">Date Ascending</option>
                <option value="dateDesc">Date Descending</option>
              </select>
            </div>
            <div className="files-list">
              <div className="files_list">
                {sortedFiles.length > 0 ? (
                  sortedFiles.map((file, index) => (
                    <div
                      className={`file-item ${
                        file._id === isSelectedID ? "active" : ""
                      }`}
                      key={index}
                    >
                      <img
                        src={file.SnapShotURL}
                        alt="snapshot"
                        onClick={() => HandleSelectFile(file._id)}
                      />
                      {editIndex === index ? (
                        <label>
                          <input
                            type="text"
                            value={rename}
                            onChange={(e) => RenameChange(e.target.value)}
                          />
                          <i
                            className="icon-check"
                            onClick={() => updateFileName(file._id)}
                          ></i>
                          <i className="icon-close" onClick={handleCancel}></i>
                        </label>
                      ) : (
                        <span
                          onClick={() =>
                            RenameFile(file.PreviewFileName, index)
                          }
                        >
                          {file.PreviewFileName} <i class="icon-edit"></i>
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <div>
                    <h4>No files found.</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            ref={dropRef}
            className={`modal-body uploads ${
              tab === "upload" ? "active" : ""
            } ${drag ? "ready" : ""}`}
          >
            {error && <span className="error_msg">{error}</span>}
            <div className="drag-file-area">
              <label className="label" htmlFor="myFile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                </svg>
                <h3 className="dynamic-message">Drag &amp; drop file here</h3>
                or{" "}
                <span className="browse-files">
                  <span className="browse-files-text">browse file</span>
                  <span>from device</span>
                </span>
                <span>Please upload .stl files only</span>
              </label>
              <input
                type="file"
                id="myFile"
                accept=".stl"
                onChange={handleUploadFile}
                className="default-file-input"
              />
            </div>
          </div>
          {tab === "files" && (
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary ${isSelectedID ? "" : "disabled"}`}
                onClick={handleRunViewer}
              >
                Select
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalDailogue;
