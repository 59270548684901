import React, { useEffect, useState } from 'react';
import Qty from '../features/Qty';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CartList, DeleteCart, UpdateCart } from '../../store/CartSlice';
import Loader from '../features/Loader';
import useSize from '../features/useSize';

function Cart ( ) {
    const [cartItems, setCartItems] = useState([]);
    const dispatch = useDispatch();
    const size = useSize();
    var CartData = useSelector(state => state.cartState);
    const CartLists = CartData.status === "success" && CartData.cart.CartItems;
    const subtotal = CartData.status === "success" && CartData.cart.TotalCartPrice;
    
    useEffect(() => {
        dispatch(CartList());
    }, [dispatch])

    function changeQty ( value, QuoteNumber, colorId ) {
        const existingItemIndex = cartItems.findIndex(item => item.QuoteNumber === QuoteNumber && item.ColorId === colorId);
        if (existingItemIndex !== -1) {
            const updatedCart = [...cartItems];
            updatedCart[existingItemIndex].Quantity = value;
        } else {
            setCartItems(prevCart => [...prevCart, { QuoteNumber, ColorId: colorId, Quantity: value }]);
        }
    }
    
    function removeProFromCart(QuoteNumber, colorId){
        const body = {
            "QuoteNumber": QuoteNumber,
            "ColorId": colorId
        }
        dispatch(DeleteCart(body));
        setTimeout( () => {
            dispatch(CartList());
        }, 400 );
    }
    function updateCart ( e ) {
        let button = e.currentTarget;
        button.querySelector( '.icon-refresh' ).classList.add( 'load-more-rotating' );
        dispatch(UpdateCart(cartItems))
        setTimeout( () => {
            dispatch(CartList());
            button.querySelector( '.icon-refresh' ).classList.remove( 'load-more-rotating' );
        }, 300 );
    }

    return (
        <div className="main">
            <Link to='/' className='gradient_btn cart_btn'>Back to print</Link>
            <div className="page-content pb-5 pt-5">
                <div className="cart">
                    <div className="cart-items container">
                        {
                            CartData.status === "success" ? 
                            CartLists.length > 0 ?
                                <div className="row">
                                    <div className="col-lg-9">
                                        {/* cart items in table */}
                                        {
                                        size < 768 ? 
                                            <div className='mobile_table_view'>
                                                {
                                                CartLists && CartLists.map( ( product, index ) => {
                                                    return(
                                                        <div className='item' key={index}>
                                                            <figure className="product-media-loaded">
                                                                <span className="product-image">
                                                                    <img src={product.SnapShotURL } alt = { product.QuoteNumber } />
                                                                </span>
                                                            </figure>
                                                            <div className='product-details'>
                                                                <h4 className="product-title">{ product.QuoteNumber }</h4>
                                                                <span className='mat-col'>
                                                                    {product.MaterialName} - {product.ColorName} 
                                                                </span>
                                                                <span className='addional'>
                                                                    <span className="out-price">₹<del>{ product.Price }</del>{" "}{product.QuotePrice}</span>
                                                                </span>
                                                                <div className='actions'>
                                                                    <div className="dropdown">
                                                                    <Qty value={ product.Quantity } changeQty={ current => changeQty( current, product.QuoteNumber, product.ColorId ) } adClass="cart-product-quantity"></Qty>
                                                                    </div>
                                                                    <button
                                                                        className="btn-remove"
                                                                        onClick={ () => removeProFromCart( product.QuoteNumber,product.ColorId ) }
                                                                    >
                                                                        <i className="icon-close"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            :
                                            <table className="table table-cart table-mobile">
                                                <thead>
                                                    <tr>
                                                        <th>QuoteNumber</th>
                                                        <th>Material</th>
                                                        <th>Color</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    { CartLists.length > 0 ?
                                                        CartLists && CartLists.map( ( item, index ) => {
                                                            return (
                                                                <tr key={ index }>
                                                                    <td className="product-col">
                                                                        <div className="product">
                                                                            <figure className="product-media">
                                                                                <span className="product-image">
                                                                                    <img src={item.SnapShotURL } alt="product" />
                                                                                </span>
                                                                            </figure>
                                                                            <div className='product-disc'>
                                                                                <h4 className="product-title">{ item.QuoteNumber }</h4>
                                                                                <span>
                                                                                    {item.FileName}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="price-col material">
                                                                        {item.MaterialName}
                                                                    </td><td className="price-col">
                                                                        {item.ColorName}
                                                                    </td>
                                                                    <td className="price-col">
                                                                        ₹{item.QuotePrice}
                                                                    </td>

                                                                    <td className="quantity-col">
                                                                        <Qty value={ item.Quantity } changeQty={ current => changeQty( current, item.QuoteNumber, item.ColorId ) } adClass="cart-product-quantity"></Qty>
                                                                    </td>

                                                                    <td className="total-col">
                                                                        ₹{ item.TotalPrice }
                                                                    </td>

                                                                    <td className="remove-col">
                                                                        <span className="btn-remove" onClick={ () => removeProFromCart( item.QuoteNumber,item.ColorId ) }><i className="icon-close"></i></span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        ) :
                                                        <tr>
                                                            <td>
                                                                <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        }
                                        {/* actions if cart more than 4 in mobile */}

                                        { (CartLists.length > 4 && size < 768) &&
                                        <div className='conditional-btns'>
                                            <div className='actions mb-2'>
                                                <Link to="/" className="btn btn-outline-primary-2 btn-block">CONTINUE SHOPPING</Link>
                                                <div className="cart-bottom">
                                                    <button className="btn btn-outline-primary-2" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></button>
                                                </div>
                                            </div>
                                            <aside className='col-lg-3 mobile_cart_summary_aside'>
                                                <div className='mobile_cart_summary'>
                                                    <div className='cart-total'>
                                                        <span className="summary-title">Cart Total : </span>
                                                        <span>₹{ subtotal}</span>
                                                    </div>
                                                    <Link
                                                        className={`${CartLists.length > 0 ? 'btn btn-primary btn-order btn-block' : 'btn-disabled'}`}
                                                        to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/print`}
                                                    >
                                                        PROCEED TO CHECKOUT
                                                    </Link>
                                                </div>
                                            </aside>
                                        </div>
                                        }
                                    </div>
                                    {
                                    size < 768 ?
                                    <aside className='col-lg-3 mobile_cart_summary_aside'>
                                        <div className='mobile_cart_summary'>
                                            <div className='cart-total'>
                                                <span className="summary-title">Cart Total : </span>
                                                <span>₹{ subtotal}</span>
                                            </div>
                                            <Link
                                                className={`${CartLists.length > 0 ? 'btn btn-primary btn-order btn-block' : 'btn-disabled'}`}
                                                to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/print`}
                                            >
                                                PROCEED TO CHECKOUT
                                            </Link>
                                        </div>
                                        <div className='actions'>
                                            <Link to="/" className="btn btn-outline-primary-2 btn-block">CONTINUE SHOPPING</Link>
                                            <div className="cart-bottom">
                                                <span className="btn btn-outline-primary-2" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></span>
                                            </div>
                                        </div>
                                    </aside>
                                    :
                                    <aside className="col-lg-3 desktop-cart-shipping">
                                        <div className="summary summary-cart">
                                            <h3 className="summary-title">Cart Total</h3>
                                            <table className="table table-summary">
                                                <tbody>
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>₹{ subtotal}</td>
                                                    </tr>
                                                    <tr className="summary-shipping">
                                                        <td>Shipping:</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr className="summary-shipping summary-shipping-content">
                                                        <td>Shipping options are available on checkout</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>₹{ subtotal }</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <Link
                                                className={`${CartLists.length > 0 ? 'gradient_btn btn-order btn-block' : 'btn-disabled'}`}
                                                to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/print`}
                                            >
                                                PROCEED TO CHECKOUT
                                            </Link>
                                        </div>
                                        <div className='actions'>
                                            <div className="cart-bottom">
                                                <button className="gradient_btn" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></button>
                                            </div>
                                            <Link to="/" className="gradient_btn btn-block">CONTINUE SHOPPING</Link>
                                        </div>
                                    </aside>
                                    }
                                </div>
                                :
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cart-empty-page text-center">
                                            <i className="cart-empty icon-shopping-cart" style={ { lineHeight: 1, fontSize: '15rem' } }></i>
                                            <p className="px-3 py-2 cart-empty mb-3">No products added to the cart</p>
                                            <p className="return-to-shop mb-0">
                                                <Link
                                                    to="/"
                                                    className="gradient_btn"
                                                >RETURN TO PRINT</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loader />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Cart;