import React, { useEffect, useState } from 'react';
import './quotes.css';
import { instance } from '../../utils/ApiRequest';
import QuoteTable from './QuoteTable';
import { Link, useLocation } from 'react-router-dom';

function MyQuotes() {
    const[quotes, setQuotes] = useState([]);
    const [UserItems, setUserItems] = useState([]);

    const location = useLocation();
    const quoteState = location.state ? location.state.tab : null;

    // console.log(quoteState);

    async function FetchUserQuotes(){
        await instance.get('/print/quotes/list')
        .then(res => {
            // console.log(res.data.Data);
            setQuotes(res.data.Data)
        })
        .catch(err => {
        })
    };

    async function FetchUserItems(){
        await instance.get('/print/user-items/list')
        .then(res => {
            setUserItems(res.data.Data);
        })
        .catch(err => {
        })
    }
    useEffect(() => {
        FetchUserItems();
        FetchUserQuotes();
    },[])
  return (
    <div className='container'>
        <div className='my_quotes'>
            <Link to='/' className='gradient_btn'>Back to print</Link>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${quoteState === null && 'active'}`} id="approved-tab" data-bs-toggle="tab" data-bs-target="#approved" type="button" role="tab" aria-controls="approved" aria-selected="true">Approved</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${quoteState === 'pending' && 'active'}`} id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="false">Pending</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="rejected-tab" data-bs-toggle="tab" data-bs-target="#rejected" type="button" role="tab" aria-controls="rejected" aria-selected="false">Rejected</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade ${quoteState === null && 'show active'}`} id="approved" role="tabpanel" aria-labelledby="approved-tab">
                    <QuoteTable quotes={quotes && quotes.filter(item => item.Status === "Approved")} status="Approved" UserItems={UserItems} />
                </div>
                <div className={`tab-pane fade ${quoteState === "pending" && 'show active'}`} id="pending" role="tabpanel" aria-labelledby="pending-tab">
                    <QuoteTable quotes={quotes && quotes.filter(item => item.Status === "Pending")}  status = "Pending" UserItems={UserItems} />
                </div>
                <div className="tab-pane fade" id="rejected" role="tabpanel" aria-labelledby="rejected-tab">
                    <QuoteTable quotes={quotes && quotes.filter(item => item.Status === "Rejected")} status = "Rejected" UserItems={UserItems} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyQuotes