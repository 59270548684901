import { configureStore } from "@reduxjs/toolkit";
import CartSlice from "./CartSlice";
import Items from "./ItemsSlice";

const store = configureStore({
    reducer:{
        cartState: CartSlice,
        Items: Items
    }
})

export default store;