import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { instance } from '../../utils/ApiRequest';
import './quotes.css';

function QuoteDetail() {
    const {id} = useParams();
    const [quoteDetail, setQuoteDetail] = useState(null);

    const FetchQuoteData = useCallback(() => {
        instance.get(`/print/quotes/${id}/fetch`)
        .then(res => {
            setQuoteDetail(res.data.Data);
        })
        .catch(err => {
            // console.log(err);
        })
    },[id])

    useEffect(() => {
        FetchQuoteData();
    },[FetchQuoteData])
  return (
    <div className='container'>
        <Link to='/quotes' className='gradient_btn'>View all Quotes</Link>
        <div className='row'>
            {quoteDetail && 
                <>
                    <div className='col-md-6 tracking_wrap'>
                      <div className="tracking-wrapper">
                          <div className="tracking">
                              <div id="progress" className={`progress-${(quoteDetail.Status === "Rejected" || quoteDetail.Status === "Approved") ? "7" : "5"} ${quoteDetail.Status === "Rejected" && "warning"}`}>
                                  <div className="empty-bar"></div>
                                  <div className="color-bar"></div>
                                  <ul>
                                      <li className="bullet-1">
                                          <div className="el"><i className='checkmark'></i></div>
                                          <div className="txt">Pending</div>
                                      </li>
                                      <li className="bullet-4">
                                          <div className="el">{quoteDetail.Status === "Rejected" ? <i class="bi bi-x"></i> : <i className='checkmark'></i>}</div>
                                          <div className="txt">{quoteDetail.Status === "Rejected" ? "Rejected" : "Approved"}</div>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div className='col-md-6 order_detail'>
                        <h4>Quote Summary</h4>
                        <div className='order-data'>
                            <span>Quote: <strong>{quoteDetail.QuoteNumber}</strong></span>
                            <span>Total: <strong>{"₹" +quoteDetail.QuotePrice || (quoteDetail.Status === "Rejected" ? "N/A" : "TBD")}</strong></span>
                        </div>
                    </div>
                    <h3>Print Options</h3>
                    <table className='table quote_table'>
                        <tbody>
                            <tr>
                                <td> File Name</td>
                                <td>{quoteDetail.FileName}</td>
                            </tr>
                            <tr>
                                <td> Weight</td>
                                <td>{quoteDetail.Weight || (quoteDetail.Status === "Rejected" ? "N/A" : "TBD")}</td>
                            </tr>
                            <tr>
                                <td> Material</td>
                                <td>{quoteDetail.MaterialName}</td>
                            </tr>
                            <tr>
                                <td> Color</td>
                                <td>{quoteDetail.ColorName}</td>
                            </tr>
                            <tr>
                                <td>Infill</td>
                                <td>{quoteDetail.Infill}</td>
                            </tr> <tr>
                                <td> PrintTime</td>
                                <td>{quoteDetail.PrintTime || (quoteDetail.Status === "Rejected" ? "N/A" : "TBD")}</td>
                            </tr>
                            <tr>
                                <td> LayerHeight</td>
                                <td>{quoteDetail.LayerHeight}</td>
                            </tr>
                            {
                                quoteDetail.Status === "Rejected" &&
                                <tr>
                                    <td>RejectReason</td>
                                    <td>{quoteDetail.RejectReason}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </>
            }
        </div>
    </div>
  )
}

export default QuoteDetail