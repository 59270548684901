import axios from "axios";


export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API URL
    withCredentials: true // Enable sending cookies when making requests
});


// add recent searches

export const AddRecentSearches = async(search_term) => {
    await instance.post('/print/searches/recent/add', {
        "search": search_term
    })
    .then(res => {
    })
    .catch(err => {
    })
}


// Function to start loader
const startLoader = () => {
    const loaderElement = document.getElementById('loader');
    if (loaderElement) {
      loaderElement.style.display = 'block';
    }
  };
  
  // Function to stop loader
  const stopLoader = () => {
    const loaderElement = document.getElementById('loader');
    if (loaderElement) {
      loaderElement.style.display = 'none';
    }
  };
  
  instance.interceptors.request.use(
    (config) => {
      // need to show loader
      startLoader();
      return config;
    },
    (error) => {
      if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
        localStorage.removeItem('user_logged_in');
        window.location.href = '/login';
      }
      if(error.response){
      }
      stopLoader();
      return Promise.reject(error);
    }
  );
  
  // Add a response interceptor to decrement counter and handle errors
  instance.interceptors.response.use(
    (response) => {
      // stop the loader
      stopLoader();
      return response;
    },
    (error) => {
    // navigate
      if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
        localStorage.removeItem('user_logged_in');
        window.location.href = '/login';
      }
      if(error.response){
      }
      stopLoader();
      return Promise.reject(error);
    }
  );
  
  export function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion_event_request_quote', {
      'event_callback': callback,
      'event_timeout': 2000,
    });
    return false;
  }