import React, { useState } from 'react';
import './options.css';
import {useNavigate} from 'react-router-dom';
import { gtag_report_conversion, instance } from '../../utils/ApiRequest';

function MaterialOptions({options, setCanvasProps, CanvasProps}) {
    const id = sessionStorage.getItem('fileId');
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [error_msg, setError_msg] = useState('');
    const [selectedOptions, setSelectedOptions] = useState({
      LayerHeight: '0.20',
      Material: '',
      Color: '',
      Infill: ''
    })
    const [formData, setFormData] = useState({
      FileId: id,
      LayerHeight: '0.2',
      MaterialId: '',
      ColorId: '',
      Infill: '20',
      Scale : CanvasProps.scale,
      PositionX : CanvasProps.positionX,
      PositionY : CanvasProps.positionY,
      RotationX : CanvasProps.rotationX,
      RotationY : CanvasProps.rotationX,
      RotationZ : CanvasProps.rotationZ,
    });
    const nextStep = (val) => {
      if(val){
        setStep(step + 1)
      }
      if(step >= 2){
        if(!selectedOptions.Infill){
          setSelectedOptions({
            ...selectedOptions,
            Infill:'20%'
          })
        }else{
          setSelectedOptions({
            ...selectedOptions,
            Infill:selectedOptions.Infill
          })
        }
      }
    };
    const prevStep = () => setStep(step - 1);
  
    const selectLayer = (value, label) => {
      setFormData({ ...formData,
        LayerHeight: value,
      });
      setSelectedOptions({
        ...selectedOptions,
        LayerHeight:label
      })
    };
    const selectInfill = (value,label) => {
      setFormData({ ...formData,
        Infill: value,
      });
      setSelectedOptions({
        ...selectedOptions,
        Infill:label
      })
    }
    const selectColor = (value, matVal, color, material, colorName) => {
      setFormData({ ...formData,
        ColorId: value,
        MaterialId: matVal
      });
      setCanvasProps({...CanvasProps,
        color:color
      })
      setSelectedOptions({
        ...selectedOptions,
        Material:material,
        Color:colorName
      })
    }
    const handleSubmit = async() => {
      await instance.post('/print/quotes/request', formData)
      .then(res => {
        navigate('/quotes', { state: {tab: "pending"} });
        gtag_report_conversion();
      })
      .catch(err => {
        setError_msg(err.response.data.Message);
      })
      setTimeout(() => {
        setError_msg('');
      }, 5000);
    };

// Individual Step Components
const Step1 = ({ onNext}) => (
    <div className='step'>
      <h2>Layer Heights</h2>
      {options && options.LayerHeights.map((item, index) => (
        <div class="form-check" key={index} onClick={() => selectLayer(item.Value, item.Label)}>
          <span name="layer" className={(formData.LayerHeight === item.Value || selectedOptions.LayerHeight === item.Label.split(" ")[0]) ? "form-check-input active" : "form-check-input"}></span>
          <label class="form-check-label">
          {item.Label}
          </label>
        </div>
      ))}
      <div className='product-details-action'>
        <span onClick={() => onNext(formData.LayerHeight)} className={`gradient_btn ${formData.LayerHeight ? "" : "btn-disabled"}`}>Next</span>
      </div>
    </div>
);
  
const Step2 = ({ onNext, onBack}) => (
    <div className='step'>
      <h2>Material</h2>
      {options && options.Materials.map((item ,index) => (
        <div className='material_colors' key={index}>
            <span className='material'>{item.Label}</span>
            <div className='colors'>
              {item.Colors.map(color => (
                <span key={color.Value} className={(formData.ColorId === color.Value && formData.MaterialId === item.Value) ? "active " : ""} data-toggle="tooltip" data-placement="top" title={color.Label} style={{ backgroundColor:color.ColorHexCode }} onClick={() => selectColor(color.Value, item.Value, color.ColorHexCode, item.Label, color.Label)}></span>
              ))}
            </div>
        </div>
      ))}
       <div className='product-details-action'>
        <span onClick={onBack} className='gradient_btn'>Back</span>
        &nbsp; &nbsp;&nbsp; &nbsp;
        <span onClick={() => onNext(formData.ColorId)} className={`gradient_btn ${formData.ColorId ? "" : "btn-disabled"}`}>Next</span>
       </div>
    </div>
);
  
const Step3 = ({ onBack, onNext }) => (
    <div className='step'>
      <h2>Infill</h2>
      {options && options.Infills.map((item, index) => (
        <div class="form-check" key={index} onClick={() => selectInfill(item.Value,item.Label)}>
          <span name="layer" className={(formData.Infill === item.Value || selectedOptions.Infill === item.Label) ? "form-check-input active" : "form-check-input"}></span>
          <label class="form-check-label">
          {item.Label}
          </label>
        </div>
      ))}
       <div className='product-details-action'>
        <span onClick={onBack} className='gradient_btn'>Back</span>
        &nbsp; &nbsp;&nbsp; &nbsp;
        <span onClick={() => onNext(formData.Infill)} className={`gradient_btn`}>Next</span>
       </div>
    </div>
);
const Step4 = ({ onBack, onSubmit }) => (
  <div className='step'>
    <h6>Instructions(optional)</h6>
      <textarea id="reply-message" cols="30" rows="6" name="instructions" class="form-control" placeholder="Add instructions"></textarea>
      <div className='product-details-action'>
        <span onClick={onBack} className='gradient_btn'>Back</span>
        &nbsp; &nbsp;&nbsp; &nbsp;
        <span onClick={onSubmit} className={`gradient_btn`}>Request Quote</span>
      </div>
      <span className="error_msg">{error_msg}</span>
  </div>
);
  // Add step indicator JSX
  const stepIndicator = (
    <ul className="step-indicators">
      <li className={step >= 1 ? "active" : ""}>
        Print Quality
        <span>{selectedOptions.LayerHeight && selectedOptions.LayerHeight.split(" ")[0]+"mm"}</span>
      </li>
      <li className={step >= 2 ? "active" : ""}>
        Material
        <span>{selectedOptions.Material}<br />{selectedOptions.Color && (selectedOptions.Color)}</span>
      </li>
      <li className={step >= 3 ? "active" : ""}>
        Infills
        <span>{selectedOptions.Infill}</span>
      </li>
      <li className={step >= 4 ? "active" : ""}>
        Notes
      </li>
    </ul>
  );

  switch(step) {
    case 1:
      return (
        <div className="form-container">
          {stepIndicator}
          <Step1 onNext={nextStep}  />
        </div>
      );
    case 2:
      return (
        <div className="form-container">
          {stepIndicator}
          <Step2 onNext={nextStep} onBack={prevStep}  />
        </div>
      );
    case 3:
      return (
        <div className="form-container">
          {stepIndicator}
          <Step3 onBack={prevStep} onNext={nextStep} />
        </div>
      );
      case 4:
        return (
          <div className="form-container">
            {stepIndicator}
            <Step4 onBack={prevStep} onSubmit={handleSubmit} />
          </div>
        );
    default:
      return null;
  }
}

export default MaterialOptions