import React, { useEffect, useState } from 'react';
import { instance } from '../../utils/ApiRequest';
import Qty from '../../Components/features/Qty';
import Loader from '../../Components/features/Loader';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AddToCart } from '../../store/CartSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useSize from '../features/useSize';
import NoResult from '../features/NoResults';

function Items() {
    const [userItems, setUserItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [colors, setColors] = useState({});
    const [cartAdded, setCartAdded] = useState({});

    const dispatch = useDispatch();
    const width = useSize();

    const selectColor = (quoteNumber, value) => {
        setColors(prevColors => ({
            ...prevColors,
            [quoteNumber]: value
        }));
    };

    const onChangeQty = (quoteNumber, current) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [quoteNumber]: current
        }));
    };

    const onCartClick = (quoteNumber, defaultColor) => {
        const colorId = colors[quoteNumber] || defaultColor;
        const quantity = quantities[quoteNumber] || 1;
        const data = {
            QuoteNumber: quoteNumber,
            ColorId: colorId,
            Quantity: quantity
        };

        dispatch(AddToCart(data))
            .unwrap()
            .then(() => {
                setCartAdded(prevCartAdded => ({
                    ...prevCartAdded,
                    [quoteNumber]: true
                }));
            })
            .catch(err => {
            });

        setTimeout(() => {
            setCartAdded(prevCartAdded => ({
                ...prevCartAdded,
                [quoteNumber]: false
            }));
        }, 3000);
    };

    async function FetchUserItems() {
        setIsLoaded(true);
        await instance.get('/print/user-items/list')
            .then(res => {
                setUserItems(res.data.Data);
                setIsLoaded(false);
            })
            .catch(err => {
            });
    }

    useEffect(() => {
        FetchUserItems();
    }, []);

    return (
        <div className='container'>
            <div className='products'>
                <Link to='/' className='gradient_btn'>Back to print</Link>
                {
                    !isLoaded ?
                        userItems.length > 0 ?
                            <div className='items mobile_table_view'>
                                {userItems.map((item, index) => {
                                    const defaultColor = item.ColorOptions.length > 0 && item.ColorOptions[0].ColorId;
                                    const itemQty = quantities[item.QuoteNumber] || 1;
                                    const itemColor = colors[item.QuoteNumber] || defaultColor;
                                    return (
                                        width > 767 ?
                                            <div className='quote-item' key={index}>
                                                <div className="product product-list">
                                                    <div className="product product-7 text-center w-100">
                                                        <figure className="product-media-loaded">
                                                            <LazyLoadImage
                                                                alt={"snapshot"}
                                                                src={item.SnapShotURL}
                                                                threshold={500}
                                                                effect="black and white"
                                                                wrapperClassName="product-image"
                                                            />
                                                        </figure>
                                                        <div className="product-body">
                                                            <h3 className="product-title">{item.ItemName}</h3>
                                                            <span className='product-con'>{item.ItemDescription}</span>
                                                            <div className="product-price">
                                                                <span className="out-price">₹{item.QuotePrice.toFixed(2)}</span>
                                                            </div>
                                                            <span className='product-disc'><strong>Material:</strong> {item.MaterialName}</span>
                                                            <div className="details-filter-row details-row-size">
                                                                <label className='mr1'>Color:</label>
                                                                <div className="select-custom">
                                                                    <select
                                                                        name="color"
                                                                        className="form-control"
                                                                        value={itemColor}
                                                                        onChange={(e) => selectColor(item.QuoteNumber, e.target.value)}
                                                                    >
                                                                        {
                                                                            item.ColorOptions.length > 0 && item.ColorOptions.map((color, index) => (
                                                                                <option
                                                                                    value={color.Key}
                                                                                    key={index}
                                                                                >
                                                                                    {color.Name}
                                                                                    <span className="color-swatch" style={{ backgroundColor: color.ColorHexCode }}></span>
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="details-filter-row details-row-size">
                                                                <label className='mr1'>Qty:</label>
                                                                <Qty changeQty={(current) => onChangeQty(item.QuoteNumber, current)} value={itemQty} />
                                                            </div>
                                                            {!cartAdded[item.QuoteNumber] ?
                                                                <span
                                                                    className='btn-product gradient_btn btn-cart'
                                                                    onClick={() => onCartClick(item.QuoteNumber, defaultColor)}
                                                                >
                                                                    <span>Add to cart</span>
                                                                </span>
                                                                :
                                                                <Link to="/cart" className='btn-product gradient_btn btn-cart'><span>View cart</span></Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='mobile-item' key={index}>
                                                <div className='item'>
                                                    <figure className="product-media-loaded">
                                                        <span className="product-image">
                                                            <img src={item.SnapShotURL} alt={item.QuoteNumber} />
                                                        </span>
                                                    </figure>
                                                    <div className='product-details'>
                                                        <h4 className="product-title">{item.QuoteNumber}</h4>
                                                        <span className='mat-col'>
                                                            {item.MaterialName}
                                                        </span>
                                                        <span className='addional'>
                                                            <div className="details-filter-row details-row-size">
                                                                <div className="select-custom">
                                                                    <select
                                                                        name="color"
                                                                        className="form-control"
                                                                        value={itemColor}
                                                                        onChange={(e) => selectColor(item.QuoteNumber, e.target.value)}
                                                                    >
                                                                        {
                                                                            item.ColorOptions.length > 0 && item.ColorOptions.map((color, index) => (
                                                                                <option
                                                                                    value={color.Key}
                                                                                    key={index}
                                                                                >
                                                                                    {color.Name}
                                                                                    <span className="color-swatch" style={{ backgroundColor: color.ColorHexCode }}></span>
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <span className="out-price">₹<del>{item.Price}</del>{" "}{item.QuotePrice}</span>
                                                        </span>
                                                        <div className="dropdown">
                                                            <Qty value={itemQty} changeQty={(current) => onChangeQty(item.QuoteNumber, current)} adClass="cart-product-quantity" />
                                                        </div>
                                                        {!cartAdded[item.QuoteNumber] ?
                                                            <span
                                                                className='btn-product gradient_btn btn-cart'
                                                                onClick={() => onCartClick(item.QuoteNumber, defaultColor)}
                                                            >
                                                                <span>Add to cart</span>
                                                            </span>
                                                            :
                                                            <Link to="/cart" className='btn-product gradient_btn btn-cart'><span>View cart</span></Link>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>
                            :
                            <NoResult title="No items added" description={
                                <>
                                    <p>
                                        To get started, use your approved quotes to create new items. Once you’ve added your items, they will appear here for you to manage and showcase.
                                    </p>
                                    <p>If you need assistance, feel free to reach out to our support team.</p>
                                </>
                            } btnName="Add item" btnUrl={"/quotes"} />
                        :
                        <Loader />
                }
            </div>
        </div>
    )
}

export default Items;
