import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { AddToCart, CartList } from '../../store/CartSlice';
import { instance } from '../../utils/ApiRequest';
import useSize from '../features/useSize';

function QuoteTable({quotes, status, UserItems}) {
    const [quotenum, setQuoteNum] = useState(null);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const width = useSize();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    var CartData = useSelector(state => state.cartState);
    const CartLists = (CartData.cart.CartItems) || [];
    // add to cart
    const Addtocart = (quote_number, color_id) => {
        const isItemInCart = CartLists && CartLists.some(item => item.SKU === quote_number);
        const data = {
            "QuoteNumber": quote_number,
            "ColorId": color_id,
            "Quantity": 1
        }
        if(isItemInCart){
            navigate('/print/cart');
        }else{
            dispatch(AddToCart(data))
            .unwrap()
            .then((res) => {
                dispatch(CartList());
                navigate('/print/cart');
            })
            .catch(err => {
            });
        }
    }

    // Open modal and set quote number
    const handleAddItem = (quoteNumber, isItemAdded) => {
        setQuoteNum(quoteNumber);
        if(!isItemAdded){
            setShowModal(true);
        }else{
            navigate('/items');
        }
    };

    // Close modal
    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    // add item
    const AddasItem = async(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const body = {
            "QuoteNumber": quotenum,
            "ItemName": formData.get('item_name'),
            "ItemDescription": formData.get("item_desc")
        }
        await instance.post('/print/user-items/add', body)
        .then(res => {
            navigate('/items')
        })
        .catch(err => {
        })
    }

  return (
    <div className='quote_table'>
        {
            width < 768 ?
                quotes.length > 0 ?  quotes.map((item, index) => {
                const isItemAdded = UserItems && UserItems.some(ele => ele.QuoteNumber === item.QuoteNumber);
                const isItemInCart = CartLists && CartLists.some(ele => ele.QuoteNumber === item.QuoteNumber);
                    return(
                    <div className='mob-order' key={index}>
                        <div className='order-data'>
                            <h4>{item.QuoteNumber}</h4>
                            <span><strong>Print: </strong>{item.PrintOptions.PrinterType}</span>
                            <span><strong>Weight: </strong>{item.WeightIncludingSupports || (item.Status === "Rejected" ? "N/A" : "TBD")}</span>
                            <div className='date-icon'>
                                <span><strong>Price: </strong>{item.QuotePrice || (item.Status === "Rejected" ? "N/A" : "TBD")}</span>
                                <span>Created on {item.createdAt.split('T')[0]}</span>
                            </div>
                        </div>
                        <div className='status'>
                            {
                            status === "Approved" ? 
                            <div className='actions'>
                                <span className='gradient_btn' onClick={() => Addtocart(item.QuoteNumber,item.PrintOptions.MaterialColorId)}>
                                    {isItemInCart ? "Go to cart" : "Add to cart"}
                                </span>
                                <span className='gradient_btn' onClick={() => handleAddItem(item.QuoteNumber, isItemAdded)}>
                                    {isItemAdded ? "Go to items" : "Add as Item"}
                                </span>
                                <Link to={`/quotes/${item.QuoteNumber}`} className='gradient_btn'>View</Link>
                            </div>
                            : 
                            <Link to={`/quotes/${item.QuoteNumber}`} className='gradient_btn'>View</Link>
                            }
                        </div>
                    </div>
                )})
                :
                <div className='mob-order'>
                    <div className='no-quotes'>
                        <p>No Quotes has been added yet.</p>
                        <Link to="/" className="gradient_btn">
                            <span>Add Quotes</span><i className="icon-long-arrow-right"></i>
                        </Link>
                    </div>
                </div>
             :
            <table className="rwd-table">
                <tbody>
                <tr>
                    <th>QuoteNumber</th>
                    <th>Date</th>
                    <th>Print</th>
                    <th>{status === "Rejected" ? "Status" : "Weight"}</th>
                    <th>{status !== "Approved" ? status === "Pending" ? "Status" : "Reason" : "Price"}</th>
                    <th>Action</th>
                </tr>
                {quotes.length > 0 ?  quotes.map((item, index) => {
                const isItemInCart = CartLists && CartLists.some(ele => ele.QuoteNumber === item.QuoteNumber);
                const isItemAdded = UserItems && UserItems.some(ele => ele.QuoteNumber === item.QuoteNumber);
                return(
                    <tr key={index}>
                        <td>
                        {item.QuoteNumber}
                        </td>
                        <td>
                        {item.createdAt.split('T')[0]}
                        </td>
                        <td>
                        {item.PrintOptions.PrinterType}
                        </td>
                        <td>
                        {status === "Rejected" ? status : (item.WeightIncludingSupports + "g" || (item.Status === "Rejected" ? "N/A" : "TBD"))}
                        </td>
                        <td>
                        {status !== "Approved" ? status === "Pending" ? status : item.RejectReason : ("₹" + item.QuotePrice || (item.Status === "Rejected" ? "N/A" : "TBD"))}
                        </td>
                        {
                            status === "Approved" ? 
                            <td data-th="Action">
                                <div className='actions'>
                                    <span className='gradient_btn' onClick={() => Addtocart(item.QuoteNumber,item.PrintOptions.MaterialColorId)}>
                                        {isItemInCart ? "Go to cart" : "Add to cart"}
                                    </span>
                                    <span className='gradient_btn' onClick={() => handleAddItem(item.QuoteNumber, isItemAdded)}>
                                        {isItemAdded ? "Go to items" : "Add as Item"}
                                    </span>
                                    <Link to={`/quotes/${item.QuoteNumber}`} className='gradient_btn'>View</Link>
                                </div>
                            </td>
                                : 
                            <td data-th="Action">
                                <Link to={`/quotes/${item.QuoteNumber}`} className='gradient_btn'>View</Link>
                            </td>
                        }
                    </tr>
                )}) : 
                <>
                    <tr className='no-quotes'>
                        <td> </td>
                        <td></td>
                        <td>
                            <p>No Quotes has been added yet.</p>
                            <Link to="/" className="gradient_btn">
                                <span>Add Quotes</span><i className="icon-long-arrow-right"></i>
                            </Link>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </>
                }
                </tbody>
            </table>
        }
        {/* Background Overlay */}
        {showModal && <div className="modal-overlay" onClick={handleCloseModal}></div>}
        {showModal && (
            <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="exampleModalLabel">Item Details</h1>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <form onSubmit={AddasItem}>
                            <div className="modal-body">
                                <div className='col-md-12'>
                                    <label>Item Name*</label>
                                    <input type='text' name='item_name' className='form-control' placeholder='Enter your desired name' required />
                                </div>
                                <div className='col-md-12'>
                                    <label>Item Description (optional)</label>
                                    <textarea name='item_desc' placeholder='description...' className='form-control'></textarea>
                                    <input type="submit" className="gradient_btn" value="Submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default QuoteTable;