import React from 'react'

function Searches({recentSearches, popularSearches, AddRecentSearches, url}) {
  return (
    <div className='searches'>
        {recentSearches.length > 0 ? 
            <div className='quick_searches'>
              <h3>Recent Searches</h3>
              <div className='search_item'>
                <ul>
                  {
                    recentSearches.map((item, index) => {
                      var targetUrl = url.includes('thingiverse') ? `https://www.thingiverse.com/search?q=${item.SearchQuery}&page=1&category_id=73` : url+item.SearchQuery;
                      return (
                        <li key={index}>
                          <a href={targetUrl} target='_blank' rel="noreferrer" onClick={() => AddRecentSearches(item.SearchQuery)}>{item.SearchQuery}</a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            : ""}
            {popularSearches.length > 0 ? 
            <div className='quick_searches'>
              <h3>Popular Searches</h3>
              <div className='search_item'>
                <ul>
                  {
                    popularSearches.map((item, index) => {
                      var targetUrl = url.includes('thingiverse') ? `https://www.thingiverse.com/search?q=${item.SearchQuery}&page=1&category_id=73` : url+item.SearchQuery;
                      return (
                        <li key={index}>
                          <a href={targetUrl} target='_blank' rel="noreferrer" onClick={() => AddRecentSearches(item.SearchQuery)}>{item.SearchQuery}</a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            : ""}
    </div>
  )
}

export default Searches