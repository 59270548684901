import React, { useState } from 'react';
import { AddRecentSearches } from '../../utils/ApiRequest';

function HeaderSearch ({setUrl}) {
    const [ cat, setCat ] = useState( "https://www.printables.com/search/models?q=" );
    const [ searchTerm, setSearchTerm ] = useState( "" );
    var categories = [
        {
            site:'Printables',
            url: 'https://www.printables.com/search/models?q='
        },
        {
            site:'Thingiverse',
            url: 'https://www.thingiverse.com/search?q=&page=1&category_id=73'
        },        
        {
            site:'Mini factor',
            url: 'https://www.myminifactory.com/search/?object=1&query='
        },
        {
            site:'Cults3d',
            url: 'https://cults3d.com/en/search?q='
        },
        {
            site:'Grab3d',
            url: 'https://grabcad.com/library?page=1&time=all_time&sort=most_downloaded&categories=3d-printing&query='
        },
    ];

    function onCatSelect ( e ) {
        if(e.target.value === ''){
            setCat('https://www.printables.com/search/models?q=');
        }else{
            setCat( e.target.value );
            setUrl(e.target.value);
        }
    }

    function onSearchChange ( e ) {
        setSearchTerm( e.target.value );
    }

    function showSearchForm ( e ) {
        document
            .querySelector( '.header-search' )
            .classList.add( 'show' );
    }

    function onSubmitSearchForm ( e ) {
        e.preventDefault();
        var url = cat.includes('thingiverse') ? `https://www.thingiverse.com/search?q=${searchTerm}&page=1&category_id=73` : cat+searchTerm;
        if(searchTerm && cat){
            AddRecentSearches(searchTerm);
            window.open(url, '_blank');
        }
    }

    return (
        <div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
            <button className="search-toggle"><i className="icon-search"></i></button>
            <form action="#" method="get" onSubmit={ onSubmitSearchForm } onClick={ showSearchForm }>
                <div className="header-search-wrapper search-wrapper-wide">
                    <div className="select-custom" onChange={ ( e ) => onCatSelect( e ) }>
                        <select id="cat" name="cat">
                            {categories.map((item, index) => 
                                <option value={item.url} key={index}>{item.site}</option>
                            )}
                        </select>
                    </div>
                    <label htmlFor="q" className="sr-only" value={ searchTerm }
                        required></label>
                    <input type="text" onChange={ onSearchChange } value={ searchTerm } className="form-control" maxLength="30" name="q" placeholder="Search External Print Files ..." required />
                    <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                </div>
            </form>
        </div >
    );
}

export default  HeaderSearch;