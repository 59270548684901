// src/components/Counter.js
import React, { useState, useEffect, useRef } from 'react';

const NumberCounter = ({ target, duration }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    const handleVisibility = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); // Stop observing after the counter starts
      }
    };

    const observer = new IntersectionObserver(handleVisibility, {
      threshold: 0.1 // Adjust this threshold as needed
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let start = null;
    const step = timestamp => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const increment = Math.min(progress / duration, 1);
      setCount(Math.floor(increment * target));

      if (increment < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }, [target, duration, isVisible]);

  return <span ref={ref}>{count}</span>;
};

export default NumberCounter;
