import React from 'react';

function MainMenu() {

    return (
        <nav className="main-nav">
            <ul className="menu sf-arrows">
                <li>
                    <a href={process.env.REACT_APP_HOME_URL} className="sf-with-ul">Home</a>
                </li>
                <li>
                    <a href={`${process.env.REACT_APP_SHOP_URL}`} className="sf-with-ul" scroll={ false }>Shop</a>
                </li>
                <li className='active'>
                    <a href={`${process.env.REACT_APP_PRINT_URL}`} className="sf-with-ul">Print</a>
                </li>
                <li>
                    <a href={`${process.env.REACT_APP_SUPPORT_URL}`} className="sf-with-ul">Support</a>
                </li>
            </ul>
        </nav>
    );
}

export default MainMenu;