import React, { useEffect } from "react";
import "../Assets/landing_css/font-awesome.css";
import "../Assets/landing_css/themify-icons.css";
import "../Assets/landing_css/shortcodes.css";
import "../Assets/landing_css/main.css";
import "../Assets/landing_css/responsive.css";
import NumberCounter from "../features/NumberCounter";

function Landing() {
    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add('landing-page');
        return () => {
          document.body.classList.remove('landing-page');
        };
      }, []); 
    return (
        <div className="page">
            <div className="banner-container">
                <div>
                    <p className="banner-description">
                        Prototyping, Innovation, Hobby Projects
                    </p>
                    <h2 className="banner-title">
                        From Concept to Creation
                        <br /> 3D Printing Excellence
                    </h2>
                    <a
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded gradient_btn"
                        href={
                            process.env.REACT_APP_ACCOUNT_URL +
                            "login?redirectTo=" +
                            window.location
                        }
                    >
                        Get Started
                    </a>
                </div>
            </div>
            <div className="site-main">
                <section className="ttm-row cta-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="ttm_single_image-wrapper text-left mt_200 res-991-mt-0">
                                    <img
                                        className="img-fluid"
                                        src="https://cdn.ocean3d.in/global/print-contact.png"
                                        alt="single_02"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-xs-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    <div className="featured-icon-box icon-align-before-title style4">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-darkgrey">
                                                <i className="themifyicon ti-mobile"></i>
                                            </div>
                                        </div>
                                        <div className="featured-title">
                                            <h5>
                                                <a href="tel:+917013099879">
                                                    +91 70130 99879
                                                </a>
                                            </h5>
                                        </div>
                                        <div className="featured-desc">
                                            <p>
                                                Do you need help? Call / Chat
                                                Now.
                                            </p>
                                        </div>
                                    </div>
                                    <a
                                        className="ttm-btn chat_now ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                        href="https://api.whatsapp.com/send?phone=917013099879"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i class="fa fa-whatsapp"></i>Chat now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ttm-row welcome-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-xs-12">
                                <div className="section-title without-seperator">
                                    <div className="title-header">
                                        <h5>Welcome to Ocean 3D Print</h5>
                                        <h2 className="title">
                                            Expert 3D Printing and Design
                                            Specialists
                                        </h2>
                                    </div>
                                </div>
                                <p>
                                    Based in India, OCEAN 3D specializes in
                                    innovative 3D printing and design. Starting
                                    as a visionary project in custom 3D
                                    printing, we have grown into a leading
                                    provider of high-quality 3D-printed products
                                    and services.
                                </p>
                                <p>
                                    We bring your ideas to life through
                                    innovative 3D printing, offering a wide
                                    range of 3D-printed products for customers
                                    worldwide. With cutting-edge technology and
                                    a dedicated team of skilled professionals,
                                    we create 3D-printed solutions that make
                                    your projects stand out.
                                </p>
                                <div className="row pt-15 pb-40 res-991-pb-30">
                                    <div className="col-sm-6">
                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                            <li>
                                                <i className="fa fa-long-arrow-right"></i>
                                                <span className="ttm-list-li-content">
                                                    Precision-Driven 3D Printing
                                                    Workflow.
                                                </span>
                                            </li>
                                            <li>
                                                <i className="fa fa-long-arrow-right"></i>
                                                <span className="ttm-list-li-content">
                                                    Customized Design Solutions.
                                                </span>
                                            </li>
                                            <li>
                                                <i className="fa fa-long-arrow-right"></i>
                                                <span className="ttm-list-li-content">
                                                    Pioneers in 3D Printing
                                                    Technology.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                            <li>
                                                <i className="fa fa-long-arrow-right"></i>
                                                <span className="ttm-list-li-content">
                                                    Reliable 3D Printing
                                                    Operations.
                                                </span>
                                            </li>
                                            <li>
                                                <i className="fa fa-long-arrow-right"></i>
                                                <span className="ttm-list-li-content">
                                                    Rapid Prototyping.
                                                </span>
                                            </li>
                                            <li>
                                                <i className="fa fa-long-arrow-right"></i>
                                                <span className="ttm-list-li-content">
                                                    Trusted By Number Of
                                                    Customers.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <a
                                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                    href={
                                        process.env.REACT_APP_ACCOUNT_URL +
                                        "login?redirectTo=" +
                                        window.location
                                    }
                                >
                                    Get Started
                                </a>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="ttm_single_image-wrapper text-lg-right text-center res-991-pt-40">
                                    <img
                                        className="img-fluid"
                                        src="https://cdn.ocean3d.in/global/print-service.png"
                                        alt="single_03"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Services */}
                <section className="ttm-row services_2-section bg-img1 clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <div className="section-title title-style-center_text without-seperator">
                                    <div className="title-header">
                                        <h5>Our Exceptional Services</h5>
                                        <h2 className="title">
                                            Custom Ocean 3D Solutions for Home,
                                            Business & Beyond
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pb-90 res-991-pb-0">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="featured-icon-box icon-align-top-content style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                            <i className="themifyicon ti-ruler-alt-2"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h5>
                                                Fast and Accurate Prototyping
                                            </h5>
                                        </div>
                                        <div className="featured-desc">
                                            <p>
                                                Quickly transform your ideas
                                                into prototypes with our
                                                fast and accurate 3D
                                                printing service. Perfect for
                                                testing and refining designs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="featured-icon-box icon-align-top-content style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                            <i className="themifyicon ti-settings"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h5>
                                                Functional Parts and Components
                                            </h5>
                                        </div>
                                        <div className="featured-desc">
                                            <p>
                                                Create durable, high-performance
                                                parts and components tailored to
                                                your specific needs, ideal for
                                                practical applications and
                                                real-world use.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="featured-icon-box icon-align-top-content style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                            <i className="themifyicon ti-spray"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h5>
                                                Post-Processing for Enhanced
                                                Finishes
                                            </h5>
                                        </div>
                                        <div className="featured-desc">
                                            <p>
                                                Enhance the final appearance of
                                                your 3D-printed items with
                                                advanced post-processing
                                                techniques, ensuring a polished
                                                and professional finish.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ttm-row broken-section clearfix">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-12 col-md-12">
                                <div className="ttm-bg ttm-col-bgimage-yes ttm-bgcolor-skincolor col-bg-img-three ttm-left-span spacing-5">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                    </div>
                                    <div className="layer-content counter">
                                        <div className="row no-gutters ttm-vertical_sep">
                                            <div className="col-lg-3 col-md-6 col-sm-6 ttm-box-col-wrapper">
                                                <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-top_icon style2">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="themifyicon ti-tablet"></i>
                                                    </div>
                                                    <div className="ttm-fid-contents">
                                                        <h4 className="ttm-fid-inner">
                                                            <NumberCounter
                                                                target={25}
                                                                duration={2000}
                                                            />
                                                        </h4>
                                                        <h3 className="ttm-fid-title">
                                                            Advanced 3D Printers
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 ttm-box-col-wrapper">
                                                <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-top_icon style2">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="themifyicon ti-palette"></i>
                                                    </div>
                                                    <div className="ttm-fid-contents">
                                                        <h4 className="ttm-fid-inner">
                                                            <NumberCounter
                                                                target={20}
                                                                duration={2000}
                                                            />+
                                                        </h4>
                                                        <h3 className="ttm-fid-title">
                                                            Material/Color Options
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 ttm-box-col-wrapper">
                                                <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-top_icon style2">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="themifyicon ti-star"></i>
                                                    </div>
                                                    <div className="ttm-fid-contents">
                                                        <h4 className="ttm-fid-inner">
                                                            <NumberCounter
                                                                target={200}
                                                                duration={2000}
                                                            />+
                                                        </h4>
                                                        <h3 className="ttm-fid-title">
                                                            Happy Customers
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 ttm-box-col-wrapper">
                                                <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-top_icon style2">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="themifyicon ti-cup"></i>
                                                    </div>
                                                    <div className="ttm-fid-contents">
                                                        <h4 className="ttm-fid-inner">
                                                            <NumberCounter
                                                                target={12}
                                                                duration={2000}
                                                            />
                                                        </h4>
                                                        <h3 className="ttm-fid-title">
                                                            Award Winners
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Landing;
