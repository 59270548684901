import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../utils/ApiRequest";


export const fetchItemsList = createAsyncThunk("Items/fetchItemsList", async () => {
    try{
        const response = await instance.get(`print/user-items/list`, {
          headers: {
            'Access-Control-Allow-Origin': '*', 
          },
        });
        return response.data;
    }catch(error){
        throw error;

    }
  });


const ItemsSlice = createSlice({
    name: 'Items',
    initialState:{
        Items: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
      .addCase(fetchItemsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchItemsList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.Items = action.payload;
      })
      .addCase(fetchItemsList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    }
})

export default ItemsSlice.reducer;
