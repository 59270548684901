import React,{ useState, useEffect, useRef, useCallback } from 'react';
import './App.css';
import '../src/Components//Assets/css/fonts-molla.min.css';
import '../src/Components//Assets/css/chillax.css';
import '../src/Components//Assets/css/bootstrap-icons.min.css';
import Header from './Components/header/Header';
import MobileMenu from './Components/features/mobile-menu';
import Print from './Components/print_shop/Print';
import Footer from './Components/footer';
import { instance } from './utils/ApiRequest';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import ModalDailogue from './Components/features/ModalDailogue';
import MyQuotes from './Components/Quotes/MyQuotes';
import Cart from './Components/print_shop/Cart';
import Loader from './Components/features/Loader';
import Items from './Components/Items/Items';
import LandingPage from './Components/features/LandingPage';
import PrivateRoutes from './Components/features/PrivateRoutes';
import QuoteDetail from './Components/Quotes/QuoteDetail';
import Maintenance from './Components/Maintanance/Maintenance';
import MaintananceRoute from './Components/features/MaintenanceRoute';
import Landing from './Components/print_shop/Landing';

function App() {
  const scrollTop = useRef(0);
  const user_log = localStorage.getItem('user_logged_in');
  const [IsFileLoaded, setIsFileLoaded] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(null);
  const [IsUser, setIsUser] = useState(null);
  const [isValidSession, setIsValidSession] = useState(false);
  const [stlUrl, setStlUrl] = useState("");
  const [url, setUrl] = useState('https://www.printables.com/search/models?q=');

   //validate session cookie
const validateSessionCookie = useCallback(() =>{
  instance.get('/account/users/validate-session')
  .then(res => {
    if(res.data){
      setIsValidSession(true);
      setIsUser(res.data.Data.User);
      if(res.data.Data.User !== null){
        localStorage.setItem('user_logged_in', true);
      }else{
        localStorage.removeItem('user_logged_in');
      }
    }
  }).catch(err => {
    // set sessionCookie
    instance.post('/account/users/create-session')
    .then(res => {
      if(res.data){
        validateSessionCookie();
      }
    }).catch(err => {
      alert("Something went wrong");
    })
  })
},[])

// maintenance mode
const MaintananceMode = useCallback(() => {
  instance.get('/print/maintenance')
  .then(res => {
    if(res.status === 200){
      setIsMaintenance(res.data.Data);
      setIsValidSession(true);
    // validateSessionCookie();
    }
  })
  .catch(err => {
    validateSessionCookie();
  })
},[validateSessionCookie])


useEffect(() => {
  MaintananceMode();
}, [MaintananceMode])
  
useEffect( () => {
  window.addEventListener( 'scroll', scrollHandler, false );
  window.scrollTo(0, 0);
  scrollTop.current = document.querySelector( '#totop' );
},[])
//scroll top 
function toScrollTop () {
  window.scrollTo( {
      top: 0,
      behavior: 'smooth'
  } );
}
//end

function scrollHandler () {
    if ( window.pageYOffset >= 400 ) {
        scrollTop.current.classList.add( 'top-visible' );
    } else {
        scrollTop.current.classList.remove( 'show' );
    }
}

function hideMobileMenu () {
  document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
}
//end mobile side bar
  return (
    <div className="site">
      {
        isValidSession ? 
        <>
          {isMaintenance == null && <Header setUrl={setUrl} User={user_log}/>}
          <div className='print-body'>
            <Routes>
            <Route path='/maintenance' element={<Maintenance isMaintenance={isMaintenance} />} />
              <Route element={<MaintananceRoute isMaintenance={isMaintenance} />}>
              <Route 
                path='/' 
                element={
                  IsUser ?
                <Print 
                  url={url}
                  User={user_log} 
                  setUrl={setUrl}
                  stlUrl={stlUrl} 
                  IsFileLoaded = {IsFileLoaded}
                  setStlUrl={setStlUrl} 
                  /> :
                  // <LandingPage />
                  <Landing />
                } 
              />
                <Route element={<PrivateRoutes IsUser={user_log} />}>
                  <Route path='/quotes' element={<MyQuotes />} />
                  <Route path='/quotes/:id' element={<QuoteDetail />} />
                  <Route path='/items' element={<Items />} />
                  <Route path='/cart' element={<Cart />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </Route>
              </Route>
            </Routes>
          </div>
          {user_log && <ModalDailogue setStlUrl={setStlUrl} stlUrl={stlUrl} setIsFileLoaded={setIsFileLoaded} />}
          {isMaintenance == null &&  <Footer />}
        </>
         : 
        <Loader />
      }
      <div id='loader'>
        <Loader />
      </div>
      <div className="mobile-menu-overlay" onClick={ hideMobileMenu }></div>
      <button id="totop" title="Back to top" onClick={ toScrollTop }>
          <i className="icon-arrow-up"></i>
      </button>
      <MobileMenu />
      {user_log &&
        <div className="footer-tik">
        <ul>
          <li className='wishlist'>
            <a href={`${process.env.REACT_APP_WEB_URL}`} className="wishlist-link">
              <div className="icon">
                <i className="icon-home"></i>
                <p>Home</p>
              </div>
            </a>
          </li>
          <li className='wishlist'>
            <Link to='/quotes' className="wishlist-link">
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
              </svg>
              <p>Quotes</p>
              </div>
            </Link>
          </li>
          <li>
          <Link to='/items' className="wishlist-link">
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-text" viewBox="0 0 16 16">
                <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
              </svg>
              <p>Items</p>
              </div>
          </Link>
            </li>
        </ul>
      </div>}
    </div>
  );
}

export default App;
