import React from 'react'
import { Link } from 'react-router-dom'

function NoResult({title, description, btnName, btnUrl}) {
  return (
    <div className='no_result_found'>
        <h3>{title}</h3>
        {description}
        {
        btnName && btnUrl &&
        <div className='text-center'>
            <Link className='gradient_btn' to={btnUrl}>{btnName}</Link>
        </div>
        }
    </div>
  )
}

export default NoResult;