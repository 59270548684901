import React, { useEffect, useState } from 'react'
import { StlViewer } from 'react-stl-viewer';
import { AddRecentSearches, instance } from '../../utils/ApiRequest';
import Searches from './Searches';
import HeaderSearch from '../header/HeaderSearch';
import MaterialOptions from './MaterialOptions';
import useSize from '../features/useSize';

function Print({url, User, stlUrl, setStlUrl, IsFileLoaded, setUrl}) {
  const [recentSearches, setRecentSearches] = useState([]);
  const [popularSearches, setPopularSearches] = useState([]);
  const [materialOptions, setMaterialOptions] = useState(null);
  const [CanvasProps, setCanvasProps] = useState(
    {
      scale:100,
      positionX:108,
      positionY:116,
      rotationX:0,
      rotationY:0,
      rotationZ:0,
      color:'',
  });

  function SelectFileBtn(){
    setStlUrl('');
  }

  const size = useSize();

  async function FetchMaterialOptions(){
    await instance.get('/print/quotes/fdm/options')
    .then(res => {
      setMaterialOptions(res.data.Data);
    })
    .catch(err => {
    })
  }

  async function FetchRecentSearches(){
    await instance.get('/print/searches/recent')
    .then(res => {
      setRecentSearches(res.data.Data);
    })
    .catch(err => {
    })
  }
  async function FetchPopularSearches(){
    await instance.get('/print/searches/popular')
    .then(res => {
      setPopularSearches(res.data.Data);
    })
    .catch(err => {
    })
  }

  useEffect(() => {
    if(User !== null){
      FetchRecentSearches();
      FetchPopularSearches();
      FetchMaterialOptions();
    }
  },[User])

  const handleChange = (e) => {
    const {name, value} = e.target;
    setCanvasProps(prevState => ({
      ...prevState,
      [name]:value
    }));
  }

  const style = {
      position: 'relative',
      backgroundColor:'#fff',
      marginBottom:'20px',
      border: '2px dashed',
      height:'400px',
  }
  const model =  {
    positionX :CanvasProps.positionX || 220,
    positionY :CanvasProps.positionY || 220,
    rotationY :0.001 || 220,
    rotationX :0.001 || 220,
    rotationZ :0.001 || 220,
    color:CanvasProps.color ||'#00FFFF',
    scale: CanvasProps.scale/100
  };

  const floor = {
    gridWidth : 220,
    gridLength : 220,
  }
  return (
    <div className='container'>
      <div className='row'>
          <div className='col-md-5'>
            {
              !IsFileLoaded ? 
            !stlUrl ? 
              <div className="drop-zone" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                <span>Select or Upload File</span>
              </div>
            :
            <div className='canvas_viewer'>
              <StlViewer
                style={style}
                modelProps={model}
                floorProps={floor}
                showAxes
                orbitControls
                shadows
                url={stlUrl}
                canvasId='custom_canvas'
              />
              <button className='gradient_btn mb-3' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={SelectFileBtn}>
                Change File
              </button>
              <h5>Scale</h5>
              <div className='actions'>
                <div className='fields'>
                  <input type='number' placeholder='scale' name='scale' className='form-control' min='50' value={CanvasProps.scale} onChange={handleChange}/>
                  <p>%</p>
                </div>
              </div>
            </div>
            :
            <div className="drop-zone">
              <div class="loader--spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            }
          </div>
          <div className='col-md-7'>
            {stlUrl === '' ?
              <>
                { size > 991 &&
                <div className='search_external'>
                  <h3>External search</h3>
                  <i class="bi bi-box-arrow-up-right"></i>
                </div>}
                <HeaderSearch setUrl={setUrl}  />
                <Searches
                recentSearches={recentSearches} 
                popularSearches={popularSearches} 
                url={url}
                AddRecentSearches={AddRecentSearches}
                />
              </>
              :
              <MaterialOptions options={materialOptions} setCanvasProps={setCanvasProps} CanvasProps={CanvasProps} />
            }
          </div>
      </div>
    </div>
  )
}

export default Print