import React, { useRef } from 'react';
import StickyHeader from '../features/StickyHeader';
import CartMenu from './CartMenu';
import { Link } from 'react-router-dom';
import TopHeader from './TopHeader';
import MainMenu from './main-menu';

function Header ({ User}) {
    const ref = useRef(null);
    const headerHight = document.getElementsByClassName("header-top");
    const top = headerHight.length > 1 ?  headerHight[0].offsetHeight : 150;

    function openMobileMenu () {
        document.querySelector( 'body' ).classList.add( 'mmenu-active' );
    }

    return (
        <header className="header header-14">
            <div className="header-top bg-body-tertiary">
                <TopHeader />
            </div>
            <StickyHeader top={top} >
                <div ref={ref} className="header-middle sticky-header">
                    <div className='container'>
                        <div className="row py-2">
                            <div className="col-auto col-lg-3 col-xl-3 col-xxl-2">
                                <button className="mobile-menu-toggler" onClick={ openMobileMenu }>
                                    <i className="icon-bars"></i>
                                </button>
                                <Link to={`${process.env.REACT_APP_HOME_URL}`} className="logo">
                                    <img src={"https://cdn.ocean3d.in/global/logo-white.png"} alt="ocean-logo" />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                </Link>
                            </div>
                            <div className="col col-lg-9 col-xl-9 col-xxl-10 header-middle-right">
                                <div className="row">
                                    <div className="col-lg-8 col-xxl-4-5col">
                                        <MainMenu />
                                    </div>
                                    <div className="col-lg-4 col-xxl-5col d-flex justify-content-end align-items-center">
                                        <div className="header-dropdown-link">
                                            <Link to='items' className="wishlist-link">
                                                <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-text" viewBox="0 0 16 16">
                                                    <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
                                                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                                                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                                                </svg>
                                                </div>
                                                <p>Items</p>
                                            </Link>
                                            <Link to='/quotes' className="wishlist-link">
                                                <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
                                                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                                                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                                                </svg>
                                                </div>
                                                <p>Quotes</p>
                                            </Link>
                                            <CartMenu User={User}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </StickyHeader>
        </header>
    )
}

export default Header;